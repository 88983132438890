<template lang="pug">
.recipe-card
  i.fa-heart.recipe-card--favorite(
      v-if="$auth.user"
      :class="favorite ? 'fas' : 'fal'"
      @click="$emit('toggle-favorite')"
    )
  nuxt-link.recipe-card--upper(:to="link")
    .recipe-card--upper-image-wrapper
      img.recipe-card--upper-image(:src="imageComputed" @error="error = true")
  nuxt-link(:to="link").recipe-card--title
    span.recipe-card--name {{ title }}
  .recipe-card--upper-info
    .recipe-card--upper-info--item
      i.fal.fa-users
      span {{ persons }} personen
    .recipe-card--upper-info--item.preperation
      i.fal.fa-clock
      span.preparation-time {{ preparationTime }}
</template>

<script>
import placeholder from '~/assets/images/placeholder.png';

export default {
  name: 'RecipeCard',
  props: {
    link: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    persons: {
      type: Number,
      default: null,
    },
    preparationTime: {
      type: String,
      default: '',
    },
    favorite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    imageComputed() {
      return !this.error ? this.image : placeholder;
    },
  },
};
</script>

<style lang="scss">
.recipe-card {
  position: relative;
  display: flex;
  flex-direction: column;

  &--upper {
    position: relative;
    display: block;
    height: 246px;

    &-image-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 246px;
      border-radius: $radius;
    }

    &-image {
      width: 100%;
      height: 250px;
      transition: transform 0.25s 0s ease;
    }

    &-info {
      display: flex;
      justify-content: flex-start;

      &--item {
        text-align: left;
        margin-right: 10px;
        color: $mummysTomb;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        white-space: nowrap;

        &.preperation {
          @include line-ellipses(1, 15px);
          flex-grow: 1;
          white-space:initial;
        }

        i {
          margin-right: 6px;
          font-size: 15px;
        }
      }
    }
  }

  &--title {
    position: relative;
    margin: 15px 0 0 0;
    color: $blackOlive !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px;
  }

  &--name {
    display: block;
    width: calc(100% - 30px);
    margin: 0 0 10px 0;
    color: $blackOlive;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
  }

  &--favorite {
    position: absolute;
    right: 0;
    bottom: 4px;
    z-index: 10;
    font-size: 17px;
    cursor: pointer;

    &.fal {
      color: $grey20;

      &:hover {
        color: $grey30;
      }
    }

    &.fas {
      color: $hunterGreen;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    width: calc(100% - 40px);

    &--title {
      color: $blackOlive;
    }

    &--favorite {
      display: none;
    }
  }
  @media only screen and (min-width: 1024px) {
    // hover styles only on desktop

    &:hover {

      .recipe-card--upper-image {
        transform: scale(1.02);
      }
    }

    a.recipe-card--upper:hover {
      opacity: 1 !important;
    }
  }
}
</style>
